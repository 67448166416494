import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { ApiUrls } from "../DataService/ApiUrlsDataService";

const productListContext = createContext();

export const ProductListProvider = ({ children }) => {
  const [productList, setProductList] = useState([]);
  const [productImages, setProductImages] = useState({});
  const sessionToken = localStorage.getItem("bearerToken");
  const [showItems, setShowItems] = useState(
    sessionStorage.getItem("showItems")
      ? parseInt(sessionStorage.getItem("showItems"))
      :16
  );
  const [searchVariable, setSearchVariable] = useState("");
  const [prices, setPrices] = useState([]);
  const [propertyFilters, setPropertyFilters] = useState([]);
  const [latestReleases, setLatestRelease] = useState("");
  const [orderByRelase, setOrderByRelease] = useState("");
  const [sliderValue, setSliderValue] = useState([1, 100]);
  const [product, setProduct] = useState("ProductID");

  const apiUrl = useMemo(() => {
    let filterGroups = [];
    const groupedFilters = {};
    propertyFilters.forEach(({ propertyId, propertyValueId }) => {
      if (!groupedFilters[propertyId]) {
        groupedFilters[propertyId] = [];
      }
      groupedFilters[propertyId].push(propertyValueId);
    });

    for (const propertyId in groupedFilters) {
      const filterClause = groupedFilters[propertyId]
        .map(
          (propertyValueId) =>
            `(Product/properties/any(property: property/PropertyId eq ${propertyId} and property/PropertyValueId eq ${propertyValueId}))`
        )
        .join(" or ");
      filterGroups.push(`(${filterClause})`);
    }
    const finalFilterClause = filterGroups.join(" and ");
    return `${
      ApiUrls.productPrice
    }?$filter=PriceGroupID eq 5 and(contains(Product/Name,'${searchVariable}') or contains(Product/Code,'${searchVariable}'))
    ${finalFilterClause ? ` and ${finalFilterClause} ` : ""}
       and ((Product/properties/any(property: property/PropertyId eq 1 and property/PropertyValue/FromValue ge ${
         sliderValue[0]
       } and property/PropertyValue/ToValue le ${sliderValue[1]})))
       ${latestReleases}
     &$top=${showItems}&$orderby=${product}${orderByRelase}&$expand=Product($expand=images($select=id,SortOrder;$orderby=sortorder;$top=1),properties($expand=property,propertyvalue))`;
  }, [
    showItems,
    searchVariable,
    propertyFilters,
    latestReleases,
    orderByRelase,
    sliderValue,
    product,
  ]);

  const fetchProductList = useMemo(() => async () => {
    try {
      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${sessionToken}`,
        },
      });
      if (!response.ok) {
        throw new Error(`Failed to fetch products: ${response.statusText}`);
      }
      const data = await response.json();
      const proList = data.value.map((pro) => pro.Product);
      setProductList(proList);
    } catch (error) {
      console.error("Error fetching product list:", error);
    }
  }, [apiUrl, sessionToken]);

  const fetchProductImages = useMemo(() => async () => {
    const imageMap = {};
    await Promise.all(
      productList.flatMap(async (product) => {
        if (product.Images) {
          const imagePromises = product.Images.map(async (image) => {
            try {
              const imageUrlResponse = await fetch(
                `${ApiUrls.getProductImageApi}(id=${image.ID},height=300)`,
                {
                  headers: {
                    Authorization: `Bearer ${sessionToken}`,
                  },
                }
              );
              if (!imageUrlResponse.ok) {
                throw new Error(
                  `Failed to fetch image for product ID ${product.ID}: ${imageUrlResponse.statusText}`
                );
              }
              const blob = await imageUrlResponse.blob();
              const imageUrl = URL.createObjectURL(blob);
              return {
                productId: product.ID,
                imageUrl: imageUrl,
                imageID: image.ID,
                SortOrder: image.SortOrder,
              };
            } catch (error) {
              console.error(
                `Error fetching image for product ID ${product.ID} and image ID ${image.ID}:`,
                error
              );
              return {
                productId: product.ID,
                imageUrl: null,
                imageID: image.ID,
                SortOrder: image.SortOrder,
              };
            }
          });
          const images = await Promise.all(imagePromises);
          images.forEach(({ productId, imageUrl, imageID, SortOrder }) => {
            if (!imageMap[productId]) {
              imageMap[productId] = [];
            }
            imageMap[productId].push({ imageUrl, imageID, SortOrder });
          });
        }
      })
    );
    setProductImages(imageMap);
  }, [productList, sessionToken]);

  const fetchPrices = useCallback(async () => {
    try {
      const promises = productList.map(async (product) => {
        const apiUrl = `${ApiUrls.showPriceProduct}(${product.ID})/GetPrice(Qty=1)`;
        try {
          const response = await fetch(apiUrl, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${sessionToken}`,
            },
          });

          if (!response.ok) {
            throw new Error(
              `Failed to fetch price for product ${product.ID}: ${response.statusText}`
            );
          }

          const text = await response.text();
          if (!text) {
            console.warn(
              `Empty response for product ${product.ID}, defaulting to price 0`
            );
            return { productId: product.ID, price: 0 };
          }

          const data = JSON.parse(text);

          if (data.price === undefined || data.price === null) {
            console.warn(
              `Invalid price data for product ${product.ID}, defaulting to price 0`
            );
            return { productId: product.ID, price: 0 };
          }

          return { productId: product.ID, price: data.price };
        } catch (error) {
          console.error(
            `Error fetching price for product ${product.ID}:`,
            error
          );
          return { productId: product.ID, price: 0 };
        }
      });

      const prices = await Promise.all(promises);
      setPrices(prices);
    } catch (error) {
      console.error("Error fetching prices:", error);
    }
  }, [productList, sessionToken]);

  useEffect(() => {
    fetchPrices();

  }, [fetchPrices]);
  

  const addPropertyFilter = (propertyId, propertyValueId) => {
    setPropertyFilters((prevFilters) => [
      ...prevFilters,
      { propertyId, propertyValueId },
    ]);
  };

  const removePropertyFilter = (propertyId, propertyValueId) => {
    setPropertyFilters((prevFilters) =>
      prevFilters.filter(
        (filter) =>
          !(
            filter.propertyId === propertyId &&
            filter.propertyValueId === propertyValueId
          )
      )
    );
  };

  const resetFilters = () => {
    setPropertyFilters([]);
    setLatestRelease("");
    setOrderByRelease("");
    setLatestRelease("");
    setProduct("ProductID");
  };

  const value = useMemo(
    () => ({
      productList,
      productImages,
      setShowItems,
      showItems,
      setSearchVariable,
      prices,
      addPropertyFilter,
      removePropertyFilter,
      propertyFilters,
      resetFilters,
      setProductImages,
      setLatestRelease,
      setOrderByRelease,
      sliderValue,
      setSliderValue,
      setProduct,
      apiUrl,
      setProductList,
      fetchProductList,
      fetchProductImages
    }),
    [
      productList,
      productImages,
      showItems,
      prices,
      propertyFilters,
      sliderValue,
      apiUrl,
      fetchProductImages,
      fetchProductList,
    ]
  );

  return (
    <productListContext.Provider value={value}>
      {children}
    </productListContext.Provider>
  );
};

export const useProductList = () => useContext(productListContext);
