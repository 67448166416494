import "./SortProduct.css";
import { useProductList } from "../../Context/ProductListContext";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const SortProducts = ({ classParameter, idPara, selectedSort, setSelectedSort }) => {
  const { setOrderByRelease, setLatestRelease, setProduct } = useProductList();
  const [today, setToday] = useState("");
  const navigate = useNavigate(); // Initialize navigate function
  

  useEffect(() => {
    const currentDate = new Date();
    currentDate.setUTCHours(0, 0, 0, 0);
    const formattedDate = currentDate.toISOString().slice(0, 10);
    setToday(formattedDate);
  }, []);

  const handleChange = () => {
    setProduct("Product");
    setOrderByRelease("/ReleaseDate desc, ProductID");
    setLatestRelease(`and Product/ReleaseDate le ${today}`);
    setSelectedSort("new");
   
  };

  const handleAlphaChange = () => {
    setOrderByRelease("/Name asc, ProductID");
    setLatestRelease("");
    setProduct("Product");
    setSelectedSort("alphaAsc");
  };

  const handleAlphaZAChange = () => {
    setOrderByRelease("/Name desc, ProductID");
    setLatestRelease("");
    setProduct("Product");
    setSelectedSort("alphaDesc");
  };

  const handleFeatureChange = () => {
    setOrderByRelease("/ReleaseDate desc, ProductID");
    setLatestRelease("and Product/Featured eq true");
    setProduct("Product");
    setSelectedSort("featured");
  };

  const handlePriceDesc = () => {
    setProduct("Price desc, ProductID");
    setOrderByRelease("");
    setLatestRelease("");
    setSelectedSort("priceDesc");
  };

  const handlePriceAsc = () => {
    setProduct("Price, ProductID");
    setOrderByRelease("");
    setLatestRelease("");
    setSelectedSort("priceAsc");
  };

  const handleNavigate = () => {
    navigate(`/shop/`);
  };



  return (
    <div>
      <nav
        id="sidebarMenu"
        className={`${classParameter} d-lg-block sidebar mt-2 bg-white`}
        onChange={handleNavigate}
      >
        <div
          className="list-group list-group-flush"
          style={{ textAlign: "left" }}
        >
          <div className="accordion accordion-flush" id="accordionExample">
            <div className="accordion-item">
              <div className="accordion-header">
                <button
                  className="accordion-button collapsed fw-bolder"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                  style={{ color: "#6A6A6A" }}
                >
                  SORT
                </button>
              </div>
              <div
                id="collapseOne"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <div className="form-check">
                    <input
                      type="radio"
                      name="sortProducts"
                      id={`${idPara}-flexRadioDefault1`}
                      onChange={handleChange}
                      checked={selectedSort === "new"}
                    />
                    <label
                      className="form-check-label fw-medium"
                      htmlFor={`${idPara}-flexRadioDefault1`}
                    >
                      NEW
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      type="radio"
                      name="sortProducts"
                      id={`${idPara}-flexRadioDefault4`}
                      onChange={handleFeatureChange}
                      checked={selectedSort === "featured"}
                    />
                    <label
                      className="form-check-label fw-medium"
                      htmlFor={`${idPara}-flexRadioDefault4`}
                    >
                      FEATURED
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      type="radio"
                      name="sortProducts"
                      id={`${idPara}-flexRadioDefault5`}
                      onChange={handleAlphaChange}
                      checked={selectedSort === "alphaAsc"}
                    />
                    <label
                      className="form-check-label fw-medium"
                      htmlFor={`${idPara}-flexRadioDefault5`}
                    >
                      ALPHABETIC A-Z
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      type="radio"
                      name="sortProducts"
                      id={`${idPara}-flexRadioDefault6`}
                      onChange={handleAlphaZAChange}
                      checked={selectedSort === "alphaDesc"}
                    />
                    <label
                      className="form-check-label fw-medium"
                      htmlFor={`${idPara}-flexRadioDefault6`}
                    >
                      ALPHABETIC Z-A
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      type="radio"
                      name="sortProducts"
                      id={`${idPara}-flexRadioDefault2`}
                      onChange={handlePriceDesc}
                      checked={selectedSort === "priceDesc"}
                    />
                    <label
                      className="form-check-label fw-medium"
                      htmlFor={`${idPara}-flexRadioDefault2`}
                    >
                      PRICE HIGH-LOW
                    </label>
                  </div>

                  <div className="form-check">
                    <input
                      type="radio"
                      name="sortProducts"
                      id={`${idPara}-flexRadioDefault3`}
                      onChange={handlePriceAsc}
                      checked={selectedSort === "priceAsc"}
                    />
                    <label
                      className="form-check-label fw-medium"
                      htmlFor={`${idPara}-flexRadioDefault3`}
                    >
                      PRICE LOW-HIGH
                    </label>
                  </div>
       
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};
