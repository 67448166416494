import React, { useEffect } from "react";
import { MDBModal, MDBModalBody, MDBModalHeader, MDBInput } from "mdb-react-ui-kit";

export const CreateUser = ({
  isModalOpen,
  setIsModalOpen,
  selectedUser,
  setUserList,
  properties,
  setSelectedUser,
}) => {
  const handleClose = () => {
    setIsModalOpen(false);
    setSelectedUser(null);
  };

  const handleInputChange = (fieldName, value) => {
    setSelectedUser((prevUser) => ({
      ...prevUser,
      [fieldName]: value,
    }));
  };

  const handleSave = async () => {
    // Logic for creating or editing a user
    // Save the user and update the userList accordingly
    // Call the API to create or update the user here

    // After successful save:
    handleClose();
  };

  return (
    <MDBModal isOpen={isModalOpen} toggle={handleClose}>
      <MDBModalHeader toggle={handleClose}>
        {selectedUser?.ID ? "Edit User" : "Create User"}
      </MDBModalHeader>
      <MDBModalBody>
        <MDBInput
          name="usersName"
          id="usersName"
          wrapperClass="mb-3"
          value={selectedUser?.Name || ""}
          onChange={(e) => handleInputChange("Name", e.target.value)}
          label={properties[5]?.Name}
          type="text"
          size="md"
        />
        <MDBInput
          name="userEmail"
          id="userEmail"
          wrapperClass="mb-2"
          value={selectedUser?.Username || ""}
          onChange={(e) => handleInputChange("Username", e.target.value)}
          label={properties[0]?.Name}
          type="email"
          size="md"
        />
        {/* Add more fields as necessary */}
        <button onClick={handleSave} className="btn btn-success">
          Save
        </button>
      </MDBModalBody>
    </MDBModal>
  );
};
