import { CartProvider } from "../../Context/CartContext";
import { ProductListProvider } from "../../Context/ProductListContext";
import { ProductsContainer } from "./ProductsContainer";
import { NavigationBar } from "./NavigationBar";

export const HandleRouteAndContext = () => {
  return (
    <ProductListProvider>
      <CartProvider>      
        <ProductsContainer />
      </CartProvider>
    </ProductListProvider>
  );
};
