import { useEffect, useState } from "react";
import logo2 from "../../Images/logo2.png";
import logo from "../../Images/asmodee.svg";
import "./NavigationBar.css";
import { jwtDecode } from "jwt-decode";
import { AdminList } from "../AdminList/AdminList";
import { useNavigate } from "react-router-dom";
import { useCart } from "../../Context/CartContext";
import { useProductList } from "../../Context/ProductListContext";

export const NavigationBar = () => {
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const sessionToken = localStorage.getItem("bearerToken");
  const decoded = jwtDecode(sessionToken);
  const isAdmin = decoded.role && decoded.role.includes("SystemAdmin");
  const navigate = useNavigate();
  const { cartItems } = useCart();
  const { setSearchVariable, setLatestRelease, setOrderByRelease, setProduct } =
    useProductList();

  const [today, setToday] = useState("");
  const [last30, setLast30] = useState("");

  useEffect(() => {
    const currentDate = new Date();
    currentDate.setUTCHours(0, 0, 0, 0);
    setToday(currentDate.toISOString().slice(0, 10));

    const last30DaysDate = new Date(currentDate);
    last30DaysDate.setDate(currentDate.getDate() - 30);
    setLast30(last30DaysDate.toISOString().slice(0, 10));
  }, []);

  


  const logOut = () => {
    sessionStorage.removeItem("showItems");
    localStorage.clear();
    navigate("/");
  };

  const [setOffcanvasVisible] = useState(false);
  const handleNavClick = () => {
    setProduct("ProductID");
    setOrderByRelease("");
    setLatestRelease("");
    setOffcanvasVisible(false); // Hide offcanvas
    navigate("/shop");
    window.location.reload();
  };

  

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-white shadow-none sticky-top">
        <div className="container-fluid">
          {/* Left logo - visible on larger screens */}
          <span id="hideSUC" className="navbar-brand d-none d-lg-block">
            <img
              src={logo}
              alt="Logo"
              className="logo-img"
              onClick={() => navigate("/shop")}
            />
          </span>

          {/* Responsive logo (visible only on smaller screens) */}

  {/* Offcanvas Toggle Button */}
  <button
    className="mt-0 border-0 bg-transparent"
    data-bs-toggle="offcanvas"
    data-bs-target="#offcanvasNavbar"
    aria-controls="offcanvasNavbar"
    aria-label="Toggle navigation"
  >
    <i className="bi bi-list" style={{ fontSize: "1.8rem" }}></i>
  </button>

          {/* Responsive logo (visible only on smaller screens) */}
          <div className="col-4 d-flex justify-content-center ">
         <div
          className="navbar-brand d-lg-none"
         style={{ display: "inline-block", height: "100%" }
         }>
            <img
              src={logo2}
              alt="Logo2"
              id="hideUC"
              className="logo-img ms-5"
              onClick={() => navigate("/shop")}
            />
          </div>
          </div>
          {/* Search bar */}
          <div className="justify-content-end me-2 d-none d-md-flex ms-auto">
            <div className="input-group w-100">
              <input
                name="searchInput"
                type="text"
                className="form-control rounded-0"
                placeholder="Search"
                aria-label="Search"
                onChange={(e) => {
                  const inputValue = e.target.value;
                  setSearchVariable(inputValue.length >= 3 ? inputValue : "");
                }}
              />
              <span className="input-group-text rounded-0">
                <i className="bi bi-search"></i>
              </span>
            </div>
          </div>
          

         {/* Conditional rendering for gear icon */}
         {isAdmin && (
            <span className="dropdown">
              <i
                className="bi bi-gear me-3"
                id="hideSUC"
                data-bs-toggle="dropdown"
                style={{ fontSize: "1.5rem" }}
              ></i>
              <ul className="dropdown-menu ">
                <AdminList />
              </ul>
            </span>
          )}



          {/* Right side icons for user profile and shopping cart */}
          <div className="d-flex align-items-center">
            <span className="dropdown me-3">
              <i
                className="bi bi-person-circle"
                data-bs-toggle="dropdown"
                style={{ fontSize: "1.5rem", cursor: "pointer" }}
              ></i>
              <ul className="dropdown-menu">
                <li>
                  <span className="dropdown-item mb-1 fw-medium" onClick={logOut}>
                    LOG OUT
                  </span>
                </li>
              </ul>
            </span>

            <span className="position-relative me-3" onClick={() => navigate("/shop/cart")}>
              <i
                className="bi bi-cart4"
                style={{ fontSize: "1.5rem", cursor: "pointer" }}
              ></i>
              {cartItems.length > 0 && (
                <span
                  className="badge rounded-pill bg-danger position-absolute top-0 start-500 translate-middle"
                  style={{ fontSize: "0.6rem" }}
                >
                  {cartItems.length}
                </span>
              )}
            </span>
          </div>
        </div>
      </nav>

      {/* Second Navbar with Offcanvas Toggle */} 
    <nav className="navbar navbar-expand-lg navbar-light bg-white border-bottom shadow-none d-none d-lg-block">
   <div className="container-fluid d-flex justify-content-between">  
  
  {/* Left side: Logo (if needed) */}
  <div className="d-none d-lg-block">
      <span className="navbar-brand">
      
      </span>
    </div>
          {/* Right side: Navigation Links */}
          <ul className="navbar-nav mb-2 fw-bolder mb-lg-0 text-end d-none d-lg-flex">
            <li className="nav-item">
              <span
                className="nav-link"
                aria-current="page"
                style={{ cursor: "pointer" }}
                onClick={() =>  {
                  setProduct("ProductID");
                  setOrderByRelease("");
                  setLatestRelease("");                
                  navigate("/shop");
                  window.location.reload(); //  page refresh
                } }
              >
                <span className="underline">PRODUCTS</span>
              </span>
            </li>
            <li className="nav-item">
              <span
                className="nav-link"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setOrderByRelease("/ReleaseDate desc, ProductID");
                  setLatestRelease(
                    `and Product/ReleaseDate ge ${last30} and Product/ReleaseDate le ${today}`
                  );
                  setProduct("Product");
                  navigate("/shop");
                }}
              >
                <span className="underline">NEW</span>
              </span>
            </li>
            <li className="nav-item">
              <span
                className="nav-link"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setOrderByRelease("/ReleaseDate asc, ProductID");
                  setLatestRelease(`and Product/ReleaseDate ge ${today}`);
                  setProduct("Product");
                  navigate("/shop");
                }}
              >
                <span className="underline">PREORDERS</span>
              </span>
            </li>
            <li className="nav-item">
              <span
                className="nav-link"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setOrderByRelease("/ReleaseDate desc, ProductID");
                  setLatestRelease("and Product/Featured eq true");
                  setProduct("Product");
                  navigate("/shop");
                }}
              >
                <span className="underline">CAMPAIGN</span>
              </span>
            </li>
            <li className="nav-item">
              <span className="nav-link" style={{ cursor: "pointer" }}>
                <span className="underline">CATALOG</span>
              </span>
            </li>
            <li className="nav-item">
              <span className="nav-link" style={{ cursor: "pointer" }}>
                <span className="underline">CONTACT</span>
              </span>
            </li>
          </ul>
        </div>
      </nav>

      {/* Offcanvas Menu */}
      <div
        className="offcanvas offcanvas-start"
        tabIndex="-1"
        id="offcanvasNavbar"
        aria-labelledby="offcanvasNavbarLabel"
      >
        <div className="offcanvas-header">
          {/* Close Button */}
          <button
            type="button"
            className="btn-close ms-auto"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <ul className="navbar-nav">
            <li className="nav-item">
              <span
                className="nav-link"
                aria-current="page"
                style={{ cursor: "pointer" }}
                onClick={() => {
               
                  setProduct("ProductID");
                  setOrderByRelease("");
                  setLatestRelease("");                    
                  navigate("/shop");              
                  window.location.reload();
            
                  
                }}
                data-bs-dismiss="offcanvas" // Close the offcanvas
              >
                PRODUCTS
              </span>
            </li>
            <li className="nav-item">
              <span
                className="nav-link"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setOrderByRelease("/ReleaseDate desc, ProductID");
                  setLatestRelease(
                    `and Product/ReleaseDate ge ${last30} and Product/ReleaseDate le ${today}`
                  );
                  setProduct("Product");                                 
                  navigate("/shop");
                }}
                data-bs-dismiss="offcanvas" // Close the offcanvas
              >
                NEW
              </span>
            </li>
            <li className="nav-item">
              <span
                className="nav-link"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setOrderByRelease("/ReleaseDate asc, ProductID");
                  setLatestRelease(`and Product/ReleaseDate ge ${today}`);
                  setProduct("Product");
                  navigate("/shop");
                
                }}
                data-bs-dismiss="offcanvas" // Close the offcanvas
              >
                PREORDERS
              </span>
            </li>
            <li className="nav-item">
              <span
                className="nav-link"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setOrderByRelease("/ReleaseDate desc, ProductID");
                  setLatestRelease("and Product/Featured eq true");
                  setProduct("Product");
                  navigate("/shop");
                }}
                data-bs-dismiss="offcanvas" // Close the offcanvas
              >
                CAMPAIGN
              </span>
            </li>
            <li className="nav-item">
              <span className="nav-link" style={{ cursor: "pointer" }}  data-bs-dismiss="offcanvas" // Close the offcanvas
              >CATALOG</span>
            </li>
            <li className="nav-item">
              <span className="nav-link"style={{ cursor: "pointer" }} data-bs-dismiss="offcanvas"  >CONTACT</span>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
