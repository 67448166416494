import { useParams } from "react-router-dom";
import image from "../../Images/image.png";
import { ImageCarousel } from "../../Carousel/Carousel";
import "./ProductPage.css";
import { useProductList } from "../../Context/ProductListContext";
import { useCart } from "../../Context/CartContext";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { useEffect } from "react";
import { CircleFlag } from "react-circle-flags";
import nophoto from "../../Images/nophotosmall.png";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';


export const ProductPage = ({
  handleQuantityChange,
  productQuantities,
  setProductQuantities,
  formatCurrency,
}) => {
  const { id } = useParams();
  const { productImages, prices, setImages, productList } = useProductList();
  const { addToCart } = useCart();
  const product = productList.find((product) => product.ID === parseInt(id));
  const price = prices.find((price) => price.productId === parseInt(id));
  const currentProductImages = productImages[id];
  const navigate = useNavigate(); // Initialize navigate function

  useEffect(() => {
    const initialQuantities = {};
    productList.forEach((product) => {
      initialQuantities[product.ID] = product.CaseQty;
    });
    setProductQuantities(initialQuantities);
  }, [productList, setProductQuantities, setImages]);

  const addItemsToCart = (productId, qty) => {
    addToCart(productId, qty);
    setProductQuantities((prevQuantities) => ({
      ...prevQuantities,
      [productId]: productList.find((product) => product.ID === productId).CaseQty,
    }));
  };

  
  if (!product) {
    return <div className="spinner-container">
    <div className="spinner"></div>
    <div className="loading-label">Loading...!</div>
</div>

  }

  const filteredProduct = product.Properties.filter(
    (property) =>
      property.Property.SortOrder !== 0 && property.Property.SortOrder !== null
  );

  const propertyId = [1, 3, 5];

  const groupedProperties = product.Properties.reduce((acc, property) => {
    const key = property.PropertyID;
    if (!acc[key]) {
      acc[key] = {
        ID: property.ID,
        PropertyID: property.PropertyID,
        PropertyName: property.Property.Name,
        PropertyValues: [],
      };
    }
    acc[key].PropertyValues.push(property.PropertyValue.Name);
    return acc;
  }, {});

  const filteredProperties = Object.entries(groupedProperties)
    .filter(([key, value]) => propertyId.includes(parseInt(value.PropertyID)))
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

  const PlayersIcon = () => (
    <svg width="20" height="20" fill="#B0B0B0" viewBox="0 0 24 24">
      <path d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z"></path>
    </svg>
  );

  const DurationIcon = () => (
    <svg width="20" height="20" fill="#B0B0B0" viewBox="0 0 24 24">
      <path d="M16.24 7.76A5.974 5.974 0 0012 6v6l-4.24 4.24c2.34 2.34 6.14 2.34 8.49 0a5.99 5.99 0 00-.01-8.48zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path>
    </svg>
  );

  const handleBackButton = () => {
    navigate(`/shop/`); // Navigate to the specified route
  };


  return (
    <div className="container">
      <div className="d-flex flex-column align-items-center justify-content-center" >
      <div className="sticky-top position-relative w-100 mb-2"  
      style={{    
      height: "9rem",
      top: "0rem", // Positioning the overlay in the center of the image
      left: "50%",
      transform: "translateX(-50%)", // Center the overlay horizontally
      zIndex: 3,     
      backgroundColor: "white",
    }}>
  <img   
    style={{ width:"80rem",height: "10.5rem", objectFit: "cover" }}
    src={image}
    alt="Game"
  />
 {/* Back button to navigate to homepage */}
<button
  onClick={handleBackButton}
  className="position-absolute"
  style={{
    top: "0rem", 
    left: "1rem", 
    backgroundColor: "transparent", 
    color: "white", 
    border: "none", 
    fontSize: "1.2rem", 
    zIndex: 4, // Ensure it appears above the image
    padding: "0.3rem 0.5rem", 
    cursor: "pointer", // 
  }}
>
  <FontAwesomeIcon icon={faChevronLeft} style={{ marginRight: "0.5rem", fontSize: "0.9rem" }} />
  Back
</button>

  <div
    className="w-75 shadow-sm position-absolute"
    style={{
      height: "7rem",
      top: "3rem", // Positioning the overlay in the center of the image
      left: "50%",
      transform: "translateX(-50%)", // Center the overlay horizontally
      zIndex: 2,
      padding: "0.5rem",
      margin: "0.5rem",
      backgroundColor: "white",
    }}
  >
    <div className="align-items-center justify-content-center">
      <div>
        <h4 className="text-center fw-bolder mt-3">{product.Name}</h4>
      </div>
      <div className="row">
        {Object.entries(filteredProperties).map(
          ([key, { PropertyName, PropertyValues, ID }]) => (
            <div key={ID} className="col text-center">
              {PropertyName === "Number of players" && <PlayersIcon />}
              {PropertyName === "Game duration" && <DurationIcon />}
              {PropertyName === "Languages" && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="#B0B0B0"
                  className="bi bi-globe-europe-africa"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0M3.668 2.501l-.288.646a.847.847 0 0 0 1.479.815l.245-.368a.81.81 0 0 1 1.034-.275.81.81 0 0 0 .724 0l.261-.13a1 1 0 0 1 .775-.05l.984.34q.118.04.243.054c.784.093.855.377.694.801-.155.41-.616.617-1.035.487l-.01-.003C8.274 4.663 7.748 4.5 6 4.5 4.8 4.5 3.5 5.62 3.5 7c0 1.96.826 2.166 1.696 2.382.46.115.935.233 1.304.618.449.467.393 1.181.339 1.877C6.755 12.96 6.674 14 8.5 14c1.75 0 3-3.5 3-4.5 0-.262.208-.468.444-.7.396-.392.87-.86.556-1.8-.097-.291-.396-.568-.641-.756-.174-.133-.207-.396-.052-.551a.33.33 0 0 1 .42-.042l1.085.724c.11.072.255.058.348-.035.15-.15.415-.083.489.117.16.43.445 1.05.849 1.357L15 8A7 7 0 1 1 3.668 2.501" />
                </svg>
              )}
              <div>
                {PropertyValues.map((value) => {
                  const countryCodeMap = {
                    Norwegian: "no",
                    Danish: "dk",
                    Swedish: "se",
                    English: "gb",
                    Finnish: "fi",
                  };
                  return countryCodeMap[value] ? (
                    <CircleFlag
                      countryCode={countryCodeMap[value]}
                      height="20"
                      key={`${ID}-${countryCodeMap[value]}`}
                    />
                  ) : (
                    <small
                      key={ID}
                      className="fw-bolder"
                      style={{ fontSize: "1rem" }}
                    >
                      {value}
                    </small>
                  );
                })}
              </div>
            </div>
          )
        )}
      </div>
    </div>
  </div>
</div>
</div>
     
      <div className="row mt-5">
        <div className="col-lg-6 col-sm-12 d-flex justify-content-center ">
          {currentProductImages && currentProductImages.length > 0 ? (
            <ImageCarousel productImages={currentProductImages} />
          ) : (
            <img
              src={nophoto}
              alt="No Content Available"
              style={{position:"fixed", width: "100%", height: "auto", maxWidth: "300px" }}
            />
          )}
        </div>
        <div className="col-lg-6 col-sm-12 justify-content-center">
          <div className="mb-5 container">
            <div className="row d-flex align-items-center">
              <div
                className="input-group col-6 col-md-6 col-sm-6"
                style={{ width: "30%" }}
              >
                <input
                  name="qtyCase"
                  min="0"
                  type="number"
                  className="form-control rounded-0 border-end "
                  aria-label="Search"
                  aria-describedby="basic-addon2"
                  value={productQuantities[product.ID] || ""}
                  onChange={(e) =>
                    handleQuantityChange(product.ID, e.target.value)
                  }
                />
                <span
                  className="input-group-text rounded-0 border-start-0"
                  onClick={() =>
                    addItemsToCart(product.ID, productQuantities[product.ID])
                  }
                >
                  <i className="bi bi-bag" style={{ fontSize: "1.1rem" }}></i>
                </span>
              </div>
              <div className="col-6 col-md-6 col-sm-6 fw-medium fw-bolder">
                {price?.price && (
                  <>
                    <span>DKK:</span>
                    <span> {formatCurrency(price.price)} </span>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="container">
            <div className="tabContainer">
              <Tabs>
                <TabList>
                  <Tab>Description</Tab>
                  <Tab>Specifications</Tab>
                  <Tab>Downloads</Tab>
                </TabList>
                <TabPanel>
                  <div className="m-4">
                    <p className="fw-bolder">{product.Description}</p>
                  </div>
                </TabPanel>
                <TabPanel>
                  <ol className="list-group list-group-flush">
                    {filteredProduct.map((pr) => (
                      <li
                        key={pr.PropertyID}
                        className="list-group-item d-flex justify-content-between align-items-start"
                      >
                        <div className="ms-2 me-auto">
                          <div className="fw-bold">{pr.Property.Name}</div>
                        </div>
                        <span className="">{pr.PropertyValue.Name}</span>
                      </li>
                    ))}
                    {product.ReleaseDate && (
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div className="fw-bold">Release Date</div>
                        </div>
                        <span className="">{product.ReleaseDate}</span>
                      </li>
                    )}
                    {product.CaseQty && (
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div className="fw-bold">Case Quantity</div>
                        </div>
                        <span className="">{product.CaseQty}</span>
                      </li>
                    )}
                    {product.Inventory && (
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div className="fw-bold">Inventory</div>
                        </div>
                        <span
                          className={
                            product.Inventory > 100
                              ? "text-success"
                              : product.Inventory > 0
                              ? "text-warning"
                              : "text-danger"
                          }
                        >
                          {product.Inventory > 100
                            ? "On stock"
                            : product.Inventory > 0
                            ? "Low on stock"
                            : "Not on stock"}
                        </span>
                      </li>
                    )}
                  </ol>
                </TabPanel>
                <TabPanel>
                  <div>Different downloads</div>
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
