import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import "./Carousel.css";

export const ImageCarousel = ({ productImages }) => {
  
  return (
    <div className="carousel-container">
      <Carousel
        showStatus={false}
        dynamicHeight={false}
        width={400}
        thumbWidth={50}
      >
        {productImages.map((image, index) => (
          <div key={index}>
            <img src={image.imageUrl} alt={`Product ${index + 1}`} />
          </div>
        ))}
      </Carousel>
    </div>
  );
};


