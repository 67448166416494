import { FilterProducts } from "../FilterAndSort/FilterProducts";
import { SortProducts } from "../FilterAndSort/SortProducts";
import { useState } from "react";
import "./Sidebar.css";

export const SideBar = () => {
  const classParameter = "collapse";
  const idPara = 1;

  const [selectedSort, setSelectedSort] = useState("");

  return ( 
    
      <div className="sidebar"> {/* Sidebar div */}
        <SortProducts
          classParameter={classParameter}
          idPara={idPara}
          selectedSort={selectedSort}
          setSelectedSort={setSelectedSort}
        />
       
        <FilterProducts
          classParameter={classParameter}
          idPara={idPara}
          setSelectedSort={setSelectedSort}
        />
      </div>
  );
};
