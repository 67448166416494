import React, { useEffect, useState, useMemo, useCallback } from "react";
import { fetchData } from "../../Utils/ReusableFunctions";
import { useProductList } from "../../Context/ProductListContext";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import "./FilterProduct.css";
import { useNavigate } from "react-router-dom";

// Debounce function to prevent excessive updates
const debounce = (func, delay) => {
  let debounceTimer;
  return (...args) => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func(...args), delay);
  };
};

export const FilterProducts = ({ classParameter, idPara, setSelectedSort }) => {
  const sessionToken = localStorage.getItem("bearerToken");
  
  const [properties, setProperties] = useState([]);
  const [propertiesValue, setPropertiesValue] = useState([]);
  const navigate = useNavigate(); // Initialize navigate function

  const {
    addPropertyFilter,
    removePropertyFilter,
    propertyFilters,
    resetFilters,
    setSliderValue,
    sliderValue,
    setShowItems,
  } = useProductList();

  // Fetch properties and property values in parallel
  useEffect(() => {
    Promise.all([
      fetchData("https://shop.decentra.net/odata/property", sessionToken),
      fetchData("https://shop.decentra.net/odata/propertyValue", sessionToken),
    ])
      .then(([propertiesData, propertyValuesData]) => {
        setProperties(propertiesData.value);
        setPropertiesValue(propertyValuesData.value);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, [sessionToken]);

  // Memoized sorted properties to avoid unnecessary recalculations
  const sortedProperties = useMemo(() => {
    return properties
      .filter((property) => property.FilterOrder !== 0 && property.FilterOrder !== null)
      .sort((a, b) => a.FilterOrder - b.FilterOrder);
  }, [properties]);

  // Handle checkbox change (debounced)
  const handleCheckboxChange = useCallback(       
    debounce((propertyId, propertyValueId) => {
      setShowItems(16);
      const filterExists = propertyFilters.some(
        (filter) => filter.propertyId === propertyId && filter.propertyValueId === propertyValueId
      );

      // Update local storage with the checkbox state
      const storedFilters = JSON.parse(localStorage.getItem("propertyFilters")) || {};
      if (filterExists) {
        removePropertyFilter(propertyId, propertyValueId);
        delete storedFilters[propertyId]?.[propertyValueId]; // Remove from storage
      } else {
        addPropertyFilter(propertyId, propertyValueId);
        storedFilters[propertyId] = {
          ...storedFilters[propertyId],
          [propertyValueId]: true, // Set the checkbox as checked
        };
      }

      // Save to local storage
      localStorage.setItem("propertyFilters", JSON.stringify(storedFilters));
    }, 500),
    [propertyFilters, addPropertyFilter, removePropertyFilter, setShowItems]
  );

  // Reset filters
  const handleResetFilters = () => {
    resetFilters();
    document.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => (checkbox.checked = false));
    localStorage.removeItem("propertyFilters");
    setSelectedSort("");
    setShowItems(16);
    setSliderValue([1, 100]);
  };

  // Handle slider change
  const handleSliderChange = (value) => {
    setSliderValue(value);
    navigate(`/shop/`);
  };

  // Handle input change for slider (debounced)
  const handleInputChange = debounce((index, newValue) => {
    const newSliderValue = [...sliderValue];
    newSliderValue[index] = Number(newValue);
    setSliderValue(newSliderValue);
  }, 500);
  
  // Get property values by property ID
  const getPropertyValuesByPropertyID = (propertyID) => {
    return propertiesValue.filter((value) => value.PropertyID === propertyID);
  };

  // Retrieve checkbox state from local storage
  const isChecked = (propertyId, valueId) => {
    const storedFilters = JSON.parse(localStorage.getItem("propertyFilters")) || {};
    return storedFilters[propertyId]?.[valueId] || false;
  };

  return (
    <div className="sidebar">
      <nav id="sidebarMenu" className={`${classParameter} sidebar d-lg-block mt-2 bg-white`}>
        <small
          className="fw-bolder ms-4"
          style={{ fontSize: "0.9rem", cursor: "pointer" }}
          onClick={() => {
            handleResetFilters(); // Call the reset function
            window.scrollTo(0, 0); // Scroll to the top of the page
          }}
          
        >
          RESET FILTERS / SORTING
        </small>

        <div className="list-group list-group-flush" style={{ textAlign: "left" }}>
          <div className="accordion accordion-flush" id="accordionFilter">
            {sortedProperties.slice(0, 16).map((property, index) => (
              <div className="accordion-item" key={property.ID}>
                <div className="accordion-header">
                  <button
                    className="accordion-button collapsed fw-bolder"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapsePlayers${index}`}
                    aria-expanded="true"
                    aria-controls={`collapsePlayers${index}`}
                    style={{ color: "#6A6A6A" }}
                  >
                    {property.Name}
                  </button>
                </div>
                <div
                  id={`collapsePlayers${index}`}
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionFilter"
                >
                  <div className="accordion-body">
                    {property.Name === "Number of players" ? (
                      <>
                        <Slider
                          range
                          step={1}
                          min={1}
                          max={100}
                          value={sliderValue}
                          onChange={handleSliderChange}
                        />
                        <div className="row rounded-0 mt-2">
                          <div className="col-6">
                            <label>From: </label>
                            <input
                              value={sliderValue[0]}
                              className="w-50"
                              onChange={(e) => handleInputChange(0, e.target.value)}
                            />
                          </div>
                          <div className="col-6">
                            <label>To: </label>
                            <input
                              value={sliderValue[1]}
                              className="w-50"
                              onChange={(e) => handleInputChange(1, e.target.value)}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      getPropertyValuesByPropertyID(property.ID).map((value, valueIndex) => (
                        <div key={value.ID} className="form-check">
                          <input
                            type="checkbox"
                            id={`player${value.PropertyID}-${valueIndex}-${idPara}`}
                            checked={isChecked(property.ID, value.ID)} // Get checkbox state from localStorage
                            onChange={() => {
                              handleCheckboxChange(property.ID, value.ID);
                              navigate(`/shop/`);
                              window.scrollTo(0, 0); // Scroll to the top of the page
                            }} 
                          />
                          <label className="form-check-label fw-medium" htmlFor={`player${value.PropertyID}-${valueIndex}-${idPara}`}>
                            {value.Name}
                          </label>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </nav>
    </div>
  );
};
