import React, { useState } from "react";
import { useShowInfo } from "../../Context/ShowInfo";
import { notify } from "../../Utils/ReusableFunctions";
import { createRole } from "../../DataService/RolesDataService";
import { ApiUrls } from "../../DataService/ApiUrlsDataService";
import { MDBInput } from "mdb-react-ui-kit";
import { fetchData } from "../../Utils/ReusableFunctions";

export const CreateRole = ({ setRoleList, showModal, onClose }) => {
  const sessionToken = localStorage.getItem("bearerToken");
  const [role, setRole] = useState({
    Name: "",
    Code: "",
    Description: "",
  });
  const { setCustomMessage } = useShowInfo();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setRole((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleCreateRole = () => {
    createRole(
      ApiUrls.roleApi,
      sessionToken,
      role,
      notify,
      setCustomMessage,
      setRole,
      fetchData,
      setRoleList
    );
    onClose(); // Close the modal after role is created
  };

  if (!showModal) return null; // Prevent rendering if modal is not visible

  return (
    <div className="modal fade show" style={{ display: "block" }}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Create Role</h5>
            <button type="button" className="btn-close" onClick={onClose}></button>
          </div>
          <div className="modal-body">
            <MDBInput
              label="Role Name"
              name="Name"
              value={role.Name}
              onChange={handleInputChange}
              className="mb-3"
            />
            <MDBInput
              label="Role Code"
              name="Code"
              value={role.Code}
              onChange={handleInputChange}
              className="mb-3"
            />
            <MDBInput
              label="Description"
              name="Description"
              value={role.Description}
              onChange={handleInputChange}
              className="mb-3"
            />
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={onClose}>
              Close
            </button>
            <button type="button" className="btn btn-primary" onClick={handleCreateRole}>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
