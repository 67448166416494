import React, { useState } from "react";
import { useShowInfo } from "../../Context/ShowInfo";
import { notify } from "../../Utils/ReusableFunctions";
import { createCurrency } from "../../DataService/CurrencyDataService";
import { ApiUrls } from "../../DataService/ApiUrlsDataService";
import { MDBInput } from "mdb-react-ui-kit";

export const CreateCurrency = ({ setCurrencyList, closeModal }) => {
  const sessionToken = localStorage.getItem("bearerToken");
  const [currency, setCurrency] = useState({
    Code: "",
    Name: "",
  });
  const { setCustomMessage } = useShowInfo();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrency((prevCurrency) => ({
      ...prevCurrency,
      [name]: value,
    }));
  };

  const handleCreateCurrency = async () => {
    try {
      await createCurrency(
        ApiUrls.currencyApi,
        sessionToken,
        currency,
        notify,
        setCustomMessage
      );
      closeModal(); // Close the modal after successful creation
      setCurrencyList((prevList) => [...prevList, currency]); // Add the new currency to the list
    } catch (error) {
      notify("Error creating currency");
      console.error("Error creating currency:", error);
    }
  };

  return (
    <div className="modal fade show" tabIndex="-1" style={{ display: "block" }}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Create Currency</h5>
            <button type="button" className="btn-close" onClick={closeModal} aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <MDBInput
              id="currencyCode"
              type="text"
              label="Code"
              name="Code"
              autoComplete="off"
              value={currency.Code}
              onChange={handleInputChange}
              className="mb-2"
            />
            <MDBInput
              id="nameCurrency"
              type="text"
              label="Name"
              name="Name"
              autoComplete="off"
              value={currency.Name}
              onChange={handleInputChange}
              className="mb-2"
            />
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={closeModal}>
              Close
            </button>
            <button type="button" className="btn btn-primary" onClick={handleCreateCurrency}>
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
