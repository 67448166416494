import { useState } from "react";
import "./LoginAuth.css";
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
} from "mdb-react-ui-kit";
import { useNavigate } from "react-router-dom";
import AuthDataService from "./AuthFunctions";
import logo from "../../Images/asmodee.svg";
import { Oval } from "react-loader-spinner";
import { notify } from "../../Utils/ReusableFunctions";

export const LogInAuth = () => {
  const navigate = useNavigate();
  const initState = {
    userID: "",
    password: "",
  };

  const [state, setState] = useState(initState);
  const [loading, setLoading] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  async function SignIn() {
    setLoading(true);
    try {
      const sessionToken = await AuthDataService.signIn(
        state.userID,
        state.password
      );
      console.log(sessionToken);
      navigate("/shop");
    } catch (error) {
      notify("Error logging in");
    } finally {
      setLoading(false);
    }
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      SignIn();
    }
  };

  return (
    <div className="loginContainer">
      <MDBContainer fluid>
        <MDBRow className="d-flex justify-content-center align-items-center ">
          <MDBCol col="12">
            <MDBCard
              className="text-white my-5 mx-auto"
              style={{
                borderRadius: "1rem",
                maxWidth: "25rem",
                backgroundColor: "rgba(0, 0, 0, 0.7)",
              }}
            >
              <MDBCardBody className="p-5 d-flex flex-column align-items-center mx-auto w-100">
                <div className="d-flex justify-content-between w-100 align-items-center">
                  <h3
                    className="fw-bold mb-2 text-uppercase"
                    style={{ flex: 1, textAlign: "left" }}
                  >
                    Login
                  </h3>
                  <img
                    src={logo}
                    alt="LoginImage"
                    style={{ height: "5rem", paddingBottom: 10 }}
                  />
                </div>
                <hr></hr>
                <MDBInput
                  name="userID"
                  id="userID"
                  wrapperClass="mb-3 mx-5 w-100"
                  className="bg-transparent text-white"
                  labelClass="text-white"
                  value={state.userID}
                  onChange={handleChange}
                  label="Email address"
                  type="email"
                  size="md"
                />

                <MDBInput
                  id="password"
                  name="password"
                  wrapperClass="mb-4 mx-5 w-100"
                  className="bg-transparent text-white"
                  labelClass="text-white"
                  value={state.password}
                  onChange={handleChange}
                  label="Password"
                  type="password"
                  size="md"
                  onKeyDown={handleKeyDown}
                />
                <MDBBtn
                  outline
                  className="mx-2 mt-3 px-5 w-100 border-white text-white d-flex justify-content-center align-items-center"
                  color="white"
                  size="md"
                  type="submit"
                  onClick={SignIn}
                  disabled={loading}
                  onKeyDown={handleKeyDown}
                >
                  {loading ? (
                    <Oval
                      secondaryColor="black"
                      visible={true}
                      height="24"
                      color="black"
                      ariaLabel="oval-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                  ) : (
                    "Sign In"
                  )}
                </MDBBtn>
                <p className="small mb-3 pb-lg-2 mt-5">
                  <a className="text-white-50" href="#!">
                    Forgot password?
                  </a>
                </p>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  );
};
