import React, { useState, useEffect, useCallback } from "react";
import { Routes, Route, Outlet, useLocation } from "react-router-dom";
import { NavigationBar } from "./NavigationBar";
import { SideBar } from "./Sidebar";
import { Products } from "../Products/Products";
import { ProductPage } from "../Products/ProductPage";
import { RouteComponent } from "../RouteComponent/RouteComponent";
import { Cart } from "../Cart/Cart";
import { FilterAndSortContainer } from "../FilterAndSort/FilterAndSortContainer";
import "./ProductsContainer.css";
import { Checkout } from "../Checkout/Checkout";
import { OrderConfirmation } from "../Checkout/OrderConfirmation";
import { useProductList } from "../../Context/ProductListContext";
import logo2 from "../../Images/logo2.png";



export const ProductsContainer = () => {
  const [isContainerVisible, setContainerVisible] = useState(true);
  const [productQuantities, setProductQuantities] = useState({});
  const [setShowItems] = useState({});
  const location = useLocation();
  
  const { fetchProductList, fetchProductImages } = useProductList();
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setContainerVisible(window.innerWidth >= 992);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    fetchProductList();
  }, [fetchProductList]);

  useEffect(() => {
    fetchProductImages();
  }, [fetchProductImages]);

  const fetchMoreData = useCallback(() => {
    console.log("fetching data");

    setTimeout(() => {
      setShowItems((prevValue) => {
        const newValue = prevValue + 16; // Calculate the new value
        sessionStorage.setItem("showItems", newValue); // Store the new value
        return newValue; // Return the new value to update state
      });
    }, 500); // Simulating an API call with a timeout
  }, [setShowItems]);

  const handleQuantityChange = (productId, quantity) => {
    quantity = parseInt(quantity, 10);
    setProductQuantities((prevQuantities) => ({
      ...prevQuantities,
      [productId]: quantity,
    }));
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat("da-DK", {
      style: "currency",
      currency: "DKK",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  };

  const hideNavAndFilters =
    location.pathname.includes("/checkout") ||
    location.pathname.includes("/orderconfirmation");
  const hideFilters = location.pathname.includes("/cart");

  return (
    <div className={`App ${isContainerVisible ? "container" : ""}`}>
      <div className="sticky-top">
        {!hideNavAndFilters && <NavigationBar />}
        <div >
        {!hideNavAndFilters && !hideFilters && (
          <div className="filterAndSort parent-container">
            <FilterAndSortContainer />          
          </div>
          
        )}   </div>   
   
       
       
        <Routes>
          <Route
            path="/"
            element={
              <div className="d-flex">              
                <div>
                  <SideBar />
                </div>
                <div className="products">
                  <Products
                    productQuantities={productQuantities}
                    handleQuantityChange={handleQuantityChange}
                    setProductQuantities={setProductQuantities}
                    formatCurrency={formatCurrency}
                  />
                </div>
              </div>
            }
          />

          <Route
            path="/product/:id"
            element={        
                 <ProductPage
                  productQuantities={productQuantities}
                  handleQuantityChange={handleQuantityChange}
                  setProductQuantities={setProductQuantities}
                  formatCurrency={formatCurrency}
                />
             
            }
          />

          <Route path="/system/*" element={<RouteComponent />} />
        </Routes>

        <Routes>
          <Route
            path="/cart"
            element={<Cart formatCurrency={formatCurrency} />}
          />
          <Route
            path="/checkout"
            element={<Checkout formatCurrency={formatCurrency} />}
          />
          <Route path="/orderconfirmation" element={<OrderConfirmation />} />
          
        </Routes>
        {/* Footer is added at the bottom */}
        {/* <div className="footer">
        <div className="footer-logo">
                  <img 
                    src={logo2} 
                    alt="Asmodee Logo" 
                    className="logo"
                  />
                </div>
                <div className="footer-bottom">
                <p>© 2024 Asmodee Webshop. All rights reserved.</p>
              </div>

        </div>*/}
      </div> 
     
</div>
   
   
  );
};
